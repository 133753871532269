.return {
    color: white;
}

#verse {
}


#verse:hover {
}

body {
    text-align: left; /* 左寄せに設定 */
    font-family: 'Arial', sans-serif;
    margin: 0;
    letter-spacing: normal; /* 文字間隔をデフォルトに設定 */
    background-color: black;
}

.catch {
    color: white;
    padding: 0em 1em;
}

h2, h3, p {
    margin-left: 0;
    letter-spacing: normal; /* 見出しや段落の文字間隔をデフォルトに設定 */
}
ruby {
    font-size: 24px;
    letter-spacing: normal; /* ルビの文字間隔もデフォルトに設定 */
}
rt,rb {
    font-size: 16px;
    color: #555;
    letter-spacing: normal; /* ルビテキストの文字間隔をデフォルトに設定 */
}
.sep {
    color: white;
    padding: 0 5px; 
}

.section {
    flex: 1;        
    padding: 10px;

}
.section + .section {
    margin-left: 20px;      
}

.container {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 2em;
    width: 80%;
    color: #FFD700;
}

@media screen and (max-width: 959px) {
	/* 959px以下に適用されるCSS（タブレット用） */
}
@media screen and (max-width: 480px) {
    .section {
        flex: 1;        
        padding: 10px;
        width: 100%;
    }
    .container {
        margin: auto;
        display: block;
        justify-content: start;
        color: #FFD700;
        padding: 1.5em 0em;
        font-size: 1.5em;
    }
    .ham {
        width: 10% !important;
    }
    .c3 {
        width: 10% !important;
        text-align: right !important;
    }
    .c1 {
        width: 90% !important;
        font-size: 1em !important;
        vertical-align: center !important;
    }
    .c11 {
        width: 0% !important;
    }
}

.leftarea {
    width: 100%;
}

html {
    scroll-padding-top: 60px; 
}

.header {
    top: 0;
    width: 100%;
    margin: 0;
    z-index: 1000;
    background: black;
    display: flex;
    position: fixed;
    top: 0;
    height: 50px;
}

.csub {
    position: fixed;
    width: 100%;
    top: 0px;
    color: #ffd700; 
    background-color: rgba(0, 0, 0, 0.5);
    height: 70px;
    padding: 8px;
    z-index: 900;
}

.ll {
    margin: auto;
    font-size: 0.8em;
    text-align: center;
    padding: 45px 0;
}

.c1 {
    text-align: left;
    color: #FFD700;
    width: 20%;
    font-size: 1em;
    padding: 0.4em 0.3em;
}

.c11 {
    text-align: left;
    color: white;    
    width: 77%;
    text-align: right;
    font-size: 1em;
    padding: 0.4em 0.3em;
}

.c3 {
    color: black;    
    text-align: center;
    padding: 0 1em;
    width: 3%;
}

.c3:hover {
    background: yellow; 
}

.ind {
    margin: auto;
    padding: 1em;
}
.pipe {
    margin: auto;
}


.c2 {
    color: white;    
}


.logo {
    height: 100%;
}

.al:hover {
   color: red;
}

.content {
    margin-top: 75px;
    margin-left: 1em;
}

a:link,
a:visited,
a:hover,
a:active {
    color: inherit; /* 親要素の色を引き継ぐ */
    text-decoration: none; /* 下線を削除 */
}
