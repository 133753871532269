.kirikantitle {
    color: #FFD700 !important;
}
.ttz {
    color: #FFD700 !important;
}

.youtube {
  margin: auto;
  padding: 1em; 
  text-align: center;
}

@media screen and (max-width: 768px) {
}

.vn {
    color: #FFD700 !important;
    padding: 0;
    margin: auto;
    vertical-align: top;
}

.v {
    width: 100%;
}

td {
    height: 1em;
}

.et {
    top: 100px !important;
    font-size: 1em;
    text-align: center;
    padding: 1.1em 0;
    height: 10px;
    margin: auto; 
}

.sharecontainer {
}

.share {
    padding: 10px;
}

.tt {
    padding: 0em 0.5em;
}

body {
    text-align: left; /* 左寄せに設定 */
    font-family: 'Arial', sans-serif;
    margin: 0;
    letter-spacing: normal; /* 文字間隔をデフォルトに設定 */
    font-size: 1.3em;
}
h3:hover {
    color: #00FFFF !important;
}
h2, h3, p {
    margin-left: 0;
    letter-spacing: normal; /* 見出しや段落の文字間隔をデフォルトに設定 */
}
ruby {
    font-size: 1em !important;
    letter-spacing: normal; /* ルビの文字間隔もデフォルトに設定 */
}

rb {
    font-size: 1em !important;
    color: #FFD700 !important;
    letter-spacing: normal; /* ルビテキストの文字間隔をデフォルトに設定 */
}

rt {
    font-size: 10px !important;
    color: #FFD700 !important;
    letter-spacing: normal; /* ルビテキストの文字間隔をデフォルトに設定 */
}

.sep {
    color: white;
    padding: 0 5px; 
}

.left-area,
.right-area {
    position: fixed;
    top: 50px;
    height: 100vh;
    width: 20%;
    color: white;
    padding: 1em; /* Add padding for content */
    box-sizing: border-box;
}

.left-area {
    left: 0; /* Fix to the left edge */
}

.right-area {
    right: 67px; /* Fix to the right edge */
}

.content {
    color: white; /* Retain text color */
    text-align: left; /* Retain text alignment */
    width: 50%;
    box-sizing: border-box; /* Include padding in width calculations */
}

.flex-container {
    display: flex;
    justify-content: center; /* Center content */
    align-items: flex-start; /* Align items at the top */
    width: 100%; /* Full width */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
}

.bibleframe {
    width: 100%;
    text-align: center;
}

em {
    padding: 0 8px 0 0;
    background: linear-gradient(45deg, #ffd700, #daa520, #ffcc00, #b8860b) !important; 
    -webkit-background-clip: text !important;
    color: transparent !important; 
}

em:hover {
    color: #00FFFF !important;
    background-color: black;

}

.toggle-button {
    background-color: #FFD700;
    color: black;
    border: none;
    cursor: pointer;
    padding: 0.5em 0.8em;
}
.toggle-button:hover {
    background-color: black !important;
    color: white;
}

@media screen and (min-width: 480px) {
    .bottom2 {
      display: none; 
    }
    .amensec {
      height: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .left-area,
    .right-area {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .left-area,
    .right-area {
        display: none;
    }

    .content {
        padding: 3.3em 0;
        margin: auto !important;
        width: 90%;
    }

    body {
        font-size: 1.3em;
    }
    .c3 {
    }
}

a:hover {
    color: yellow !important; 
}

a:link,
a:visited,
a:hover,
a:active {
    color: inherit; /* 親要素の色を引き継ぐ */
    text-decoration: none; /* 下線を削除 */
}
