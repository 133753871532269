.gridbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
  color: #FFD700;
}

.body {
}

.gridtitlepad {
    height: 50px;
}
.gridtitle {
    color: #FFD700 !important;
    text-align: center;
    width: 100%;
    margin: auto;
    text-align: center;
}

.grid-object {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.grid-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
  justify-content: flex-start; /* Align text to the top */
  height: 200px;
}

.grid-text-title {
}
.grid-text-description {
    color: white !important;
    font-size: 0.8rem !important;
}

.grid-object img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  margin-right: 1rem;
}

.grid-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}

.grid-text h3 {
  font-size: 1.125rem; /* ~text-lg */
  font-weight: bold;
  margin: 0;
}

.grid-text p {
  font-size: 0.875rem; /* ~text-sm */
  color: #4B5563; /* Tailwind's gray-600 */
  margin: 0;
}

@media (max-width: 768px) {
  .gridbox {
    grid-template-columns: 1fr; /* Switch to 1 column */
  }

  .grid-object {
    flex-direction: column; /* Stack image on top of text */
    align-items: center;
    text-align: center;
    width: 100%; /* Ensure full width */
    padding-bottom: 1rem; /* Adds spacing between items */
  }

  .grid-object img {
    margin-right: 0; /* Remove right margin when stacked */
    margin-bottom: 1rem; /* Space between image and text */
  }

  .grid-text {
    max-width: 100%; /* Ensure text takes full width */
    padding: 1em;
  }
  .grid-text-description {
      font-size: 1rem !important;
  }
}
